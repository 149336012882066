.ChatWidgetAdmin {
  height: 100vh !important;
}

.ChatWidgetUser {
  .str-chat__container {
    height: 100vh;
  }

  .str-chat__container {
    max-height: 60vh !important;
  }
  @media (min-width: 640px) {
    .str-chat__container {
      max-height: 90vh !important;
    }
  }
}

.ChatWidget {
  display: flex;

  .str-chat__header-livestream-livelabel {
    position: relative;
    left: 5px;
    font-size: 13px;
    text-transform: uppercase;
    color: red;
    display: inline-block;
    -webkit-animation: pulse 2s infinite;
    animation: pulse 2s infinite;
  }
  .str-chat__header-livestream-livelabel:before {
    content: "";
    position: relative;
    top: -2px;
    left: -4px;
    display: inline-block;
    width: 5px;
    height: 5px;
    border-radius: 100%;
    background-color: red;
  }

  .str-chat__main-panel--thread-open {
    display: none !important;
  }

  .str-chat__main-panel--thread-open + .str-chat__thread {
    flex: 1;
  }

  .str-chat__message--pinned .str-chat__message-bubble::before {
    content: "📌";
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    background-color: papayawhip;
    font-size: 0.6rem;
    width: 1.4rem;
    height: 1.4rem;
    border-radius: 9999px;
    z-index: 1;
    top: -10px;
  }
  .str-chat__message--other.str-chat__message--pinned
    .str-chat__message-bubble::before {
    right: -10px;
  }
  .str-chat__message--me.str-chat__message--pinned
    .str-chat__message-bubble::before {
    left: -10px;
  }

  .str-chat__header-hamburger {
    display: none;
  }

  .str-chat__channel-list {
    position: fixed;
    z-index: 1;
    height: 100%;
    width: 0;
    flex-shrink: 0;
    box-shadow: 0 0 8px rgba(0, 0, 0, 0.15);
    &--open {
      width: 30%;
      position: fixed;
    }
    transition: width 0.3s ease-out;
  }
  .str-chat__channel {
    flex: 1;
    min-width: 0;
  }
  .str-chat__main-panel {
    min-width: 0;
    flex: 1;
    &--thread-open {
      display: none;
    }
  }
  .str-chat__thread {
    flex: 1;
    height: 100%;
    position: fixed;
    z-index: 1;
  }

  @media screen and (min-width: 768px) {
    .str-chat__channel-list {
      width: 30%;
      position: initial;
      z-index: 0;
    }
    .str-chat__thread {
      position: initial;
      z-index: 0;
    }
  }
  @media screen and (min-width: 1024px) {
    .str-chat__main-panel {
      min-width: 0;
      &--thread-open {
        max-width: 0%;
        display: flex;
      }
    }
    .str-chat__thread {
      max-width: 100%;
    }
  }
}
